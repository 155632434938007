import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from "react-i18next";
import { Link, NavLink, useLocation, useHistory } from 'react-router-dom';
import CIcon from "@coreui/icons-react";
import { API_BASE_URL, IMAGES_BASE_URL, MEDIA_URL } from "../../config";
import axios from 'axios'
import { connect } from 'react-redux';
import * as actions from '../../reducers/actions';
import { logout } from '../middleware/auth';
import FavoriteContext from '../FrontOffice/FavoriteContext';

const Navbar = ({ basketItems, fetchBasket }) => {
    const history = useHistory();
    const location = useLocation();
    const [isMedicalPage, setisMedicalPage] = useState(false);
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const [categories, setCategories] = useState([]);
    const { favorites, pColor, sColor, tColor } = useContext(FavoriteContext);
    const [lang, setLang] = useState('en');
    const [showSticky, setShowSticky] = useState(false);


    function changeLanguage(lang) {

        i18n.changeLanguage(lang);
        setLang(lang)
    }
    const [click, setClick] = useState(false);

    const handleLogout = () => {
        logout();
    };

    const closeMobileMenu = () => {
        setClick(false);
        document.getElementById("nav").removeAttribute("data-nav-menu-open")
    }
    const handleClick = () => {
        setClick(!click);
        if (!click) {

            //document.getElementById("nav").setAttribute("data-nav-menu-open", "")
            //document.getElementById("nav").classList.add('showMenuMob');
            document.getElementById("nav").classList.remove('hideMenuMob');
        } else {
            // document.getElementById("nav").removeAttribute("data-nav-menu-open")
            //document.getElementById("nav").classList.remove('showMenuMob');
            document.getElementById("nav").classList.add('hideMenuMob');

        }
    }
    useEffect(() => {
        console.log('bskk in nav ', basketItems)
        document.getElementById("nav").classList.add('hideMenuMob');
        if (location.pathname.includes('medical-center')) setisMedicalPage(true);
        else setisMedicalPage(false);
    }, [location])

    useEffect(() => {

        // Prevent right-click menu
        document.addEventListener('contextmenu', (e) => e.preventDefault());

        // Disable text selection
        document.addEventListener('selectstart', (e) => e.preventDefault());

        fetchBasket();
        axios(`${API_BASE_URL}/categories-trainings`)
            .then((res) => res.data)
            .then(
                (result) => {
                    setCategories(result);
                },
                (error) => {
                    console.log(error);
                }
            );

    }, []);

    // Detect screen recording attempts
    setInterval(() => {
        if (window.outerWidth - window.innerWidth > 200 ||
            window.outerHeight - window.innerHeight > 200) {
            document.body.innerHTML = '<h1>Recording detected</h1>';
        }
    }, 1000);

    const handleScroll = () => {
        const header = document.querySelector(".mysticky");
        const mobHeader = document.querySelector(".mobHeader");
        const headerMob = document.querySelector(".onTopMob");
        const menuMob = document.querySelector(".l-panel--more");
        if (header) {
            const scrollTop =
                window.pageYOffset || document.documentElement.scrollTop;
            if (scrollTop <= 90) {
                header.classList.add("hidden");
                setShowSticky(false)
            } else {
                header.classList.remove("hidden");
                setShowSticky(true)
            }
        }
        if (mobHeader) {
            const scrollTop =
                window.pageYOffset || document.documentElement.scrollTop;
            if (scrollTop <= 70) {
                mobHeader.classList.remove("mobHeaderSticky");

            } else {
                mobHeader.classList.add("mobHeaderSticky");

            }
        }

        if (headerMob) {
            const scrollTop =
                window.pageYOffset || document.documentElement.scrollTop;
            if (scrollTop < 30) {
                headerMob.classList.remove("stickyMob");
                menuMob.classList.remove("scrolledMob");
            } else {
                headerMob.classList.add("stickyMob");
                menuMob.classList.add("scrolledMob");
            }
        }

    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const handleLoginClick = () => {
        closeMobileMenu();
        if (location.pathname === '/GMTC-medical-center') {
            // Add #login to the URL without reloading the page
            if (location.hash !== '#login') {
                history.push({
                    pathname: location.pathname,  // Keeps the same path ("/")
                    hash: '#login'                 // Adds the hash to the URL
                });
            }

            // Scroll to the login section
            const loginSection = document.getElementById('login');
            if (loginSection) {
                loginSection.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            // Redirect to the homepage with #login in the URL
            history.push({
                pathname: '/GMTC-medical-center',  // Redirects to the home page
                hash: '#login'  // Adds the #login hash to the URL
            });
        }
    };

    return (
        <>
            <div className="sticky mysticky hidden" style={{ backgroundColor: pColor }
                //isMedicalPage ? { backgroundColor: "#0038e1" } : { backgroundColor: "#26bbcd" }
            }
            >
                <section className="l-navbar" aria-label="Main website navigation">
                    <div className="l-navbar__top container">
                        <div className="l-navbar__inner">
                            <div
                                id="menuMain"
                                className="l-navbar__standard is-slid-and-faded-out"
                            >
                                {isMedicalPage ?
                                    <Link to="/GMTC-medical-center" aria-current="page" onClick={closeMobileMenu}
                                        className="logo w-nav-brand w--current gmtcLogoWrapper"><img
                                            src=//"/assets/images/logo-gmtc.png"
                                            "/assets/images/icons/favicon.png"
                                            alt="GMTC Logo " className="img_navlogo" />
                                    </Link>
                                    : <Link to="/" aria-current="page" onClick={closeMobileMenu}
                                        className="logo w-nav-brand w--current"><img
                                            src=//"/assets/images/logo_gdtc.png"
                                            "/favicon.ico"
                                            alt="GMTC Logo " className="img_navlogo" />
                                        {/* <CIcon name="cilHome" size={'xl'} className="primary" /> */}
                                    </Link>
                                }

                                <nav className="l-navbar__topics">
                                    <div className="l-navbar__track">
                                        <nav role="navigation" className="nav-menu w-nav-menu"  >
                                            <div className="nav_menu_content">

                                                {!isMedicalPage ? <div className="w_navleftsidelinks">

                                                    <NavLink to="/trainings/3/master_class" activeClassName="w--current" className="tl_nav_link importantLink" onClick={closeMobileMenu}>{t("master_class")}</NavLink>
                                                    <NavLink to="/trainings/5/informatique" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("Informatique")}</NavLink>
                                                    <NavLink to="/trainings/6/art_et_metier" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("Art_and_craft")}</NavLink>
                                                    <NavLink to="/trainings/7/alimentaire" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>Alimentaire</NavLink>
                                                    <NavLink to="/trainings/8/medias" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("Media")}</NavLink>
                                                    <NavLink to="/trainings/9/kids" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>Kids</NavLink>
                                                    <NavLink to="/trainings/10/metier_d_artisant" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("Craftsmanship")}</NavLink>
                                                    {/* <NavLink to="/contact" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>IOT Security</NavLink>
                                        <NavLink to="/contact" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>Soft Skills</NavLink>
                                    */}
                                                </div>
                                                    :
                                                    <div className="w_navleftsidelinks">
                                                        <NavLink to="/GMTC-medical-center/trainings/21/medical_master_class" activeClassName="w--current" className="tl_nav_link importantLink" onClick={closeMobileMenu}>{t("master_class")}</NavLink>
                                                        <NavLink to="/GMTC-medical-center/trainings/11/dental" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("Dental")}</NavLink>
                                                        <NavLink to="/GMTC-medical-center/trainings/12/ophthalmology" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("Ophthalmology")}</NavLink>
                                                        <NavLink to="/GMTC-medical-center/trainings/13/general-surgery" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("General_surgery")}</NavLink>
                                                        <NavLink to="/GMTC-medical-center/trainings/14/shoulder-surgery" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("Shoulder_surgery")}</NavLink>
                                                    </div>
                                                }
                                            </div>



                                        </nav>
                                    </div>
                                </nav>
                                <div className="stickyFloatRight">
                                    {!localStorage.getItem("auth") && isMedicalPage ?
                                        <span style={{ color: "white", verticalAlign: "super", position: 'relative', cursor: 'pointer' }} //to="/GMTC-medical-center#login" 
                                            className="tl_nav_link cc_smallermargin cc_navmediulink" onClick={handleLoginClick}>{t("login")} <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-in"><path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4" fill="white"></path><polyline points="10 17 15 12 10 7"></polyline><line x1="15" y1="12" x2="3" y2="12"></line></svg></span>
                                        : !localStorage.getItem("auth") && !isMedicalPage ? <Link to="/login" style={{ color: "white", verticalAlign: "super", position: 'relative' }} className="tl_nav_link cc_smallermargin cc_navmediulink" onClick={closeMobileMenu}>
                                            {t("login")} <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-in"><path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4" fill="white"></path><polyline points="10 17 15 12 10 7"></polyline><line x1="15" y1="12" x2="3" y2="12"></line></svg></Link>
                                            : ""}
                                    {localStorage.getItem("auth") &&
                                        <>
                                            <Link style={{ position: 'relative', marginRight: '0px' }} to={isMedicalPage ? "/GMTC-medical-center/my-profile" : "/my-profile"} className="profileIconLink tl_nav_link cc_smallermargin cc_navmediulink favIconClass2 ">
                                                <img className='profileIcon' src={localStorage.getItem("profile") ? (MEDIA_URL + localStorage.getItem("profile")) : '/assets/icons/default_avatar.jpg'} />
                                            </Link>
                                            <div className="hover-link-container">
                                                <div to="/" onClick={handleLogout} className="hover-link tl_nav_link cc_smallermargin cc_navmediulink" style={{ marginRight: "10px", cursor: "pointer" }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                                                    <span className="hover-text">{t("logout")}</span>
                                                </div>
                                            </div>
                                        </>}

                                    <div className='changeLangM hover-link-container'>
                                        <span onClick={(e) => changeLanguage('fr')}
                                            className={`${lang == 'en' ? 'showLangFlag' : 'hideLangFlag'}  tl_nav_link cc_smallermargin cc_navmediulink `}>
                                            Français <img src={"/assets/images/fr.png"} className="flag" /></span>
                                        <span onClick={(e) => changeLanguage('en')}
                                            className={`${lang == 'fr' ? 'showLangFlag' : 'hideLangFlag'} tl_nav_link cc_smallermargin cc_navmediulink`}>
                                            English <img src={"/assets/images/en.png"} className="flag" /></span>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>{" "}
                </section>
            </div>
            <nav role="navigation" className="nav-menu w-nav-menu  hideMenuMob" id="nav"  >
                <div className="d_navrightside d_navrightside1">
                    <div style={{ display: 'flex' }}>
                        {!localStorage.getItem("auth") && isMedicalPage ?
                            <span style={{ width: 'max-content' }} //to="/GMTC-medical-center#login" 
                                className="tl_nav_link cc_smallermargin cc_navmediulink" onClick={handleLoginClick}>{t("login")} <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-in"><path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4" fill="white"></path><polyline points="10 17 15 12 10 7"></polyline><line x1="15" y1="12" x2="3" y2="12"></line></svg></span>
                            : !localStorage.getItem("auth") && !isMedicalPage ? <Link to="/login" style={{ width: 'max-content' }} className="tl_nav_link cc_smallermargin cc_navmediulink" onClick={closeMobileMenu}>
                                {t("login")} <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-in"><path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4" fill="white"></path><polyline points="10 17 15 12 10 7"></polyline><line x1="15" y1="12" x2="3" y2="12"></line></svg></Link>
                                : ""}
                        {localStorage.getItem("auth") && <>
                            <div className="hover-link-container">
                                <div to="/" onClick={handleLogout} className="hover-link tl_nav_link cc_smallermargin cc_navmediulink" style={{ marginRight: "10px", cursor: "pointer" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                                    <span className="hover-text">{t("logout")}</span>
                                </div>
                            </div>

                        </>}
                        <div className='changeLangMob'>
                            <span onClick={(e) => changeLanguage('fr')}
                                className={`${lang == 'en' ? 'showLangFlag' : 'hideLangFlag'}  tl_nav_link cc_smallermargin cc_navmediulink `}>
                                <img src={"/assets/images/fr.png"} className="flag" /></span>
                            <span onClick={(e) => changeLanguage('en')}
                                className={`${lang == 'fr' ? 'showLangFlag' : 'hideLangFlag'} tl_nav_link cc_smallermargin cc_navmediulink`}>
                                <img src={"/assets/images/en.png"} className="flag" /></span>

                        </div>
                    </div>
                    <div className="d_navrightside12">

                        {isMedicalPage ?
                            <div >

                                <NavLink to="/GMTC-medical-center/trainer-apply" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("join_our_team")}</NavLink>


                            </div>
                            :
                            <div >
                                <NavLink to="/trainer-apply" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("join_our_team")}</NavLink>

                            </div>
                        }
                    </div>

                </div>

                <div className="d_navrightside">
                    {localStorage.getItem("auth") && <>
                        <Link to={isMedicalPage ? "/GMTC-medical-center/my-profile/my-items" : "/my-profile/my-items"} className="myItemsLink tl_nav_link cc_smallermargin cc_navmediulink favIconClass2 ">
                            <img className='profileIcon' src={'/assets/icons/my-course.png'} />
                        </Link>
                        <Link to={isMedicalPage ? "/GMTC-medical-center/my-profile" : "/my-profile"} className="profileIconLink tl_nav_link cc_smallermargin cc_navmediulink favIconClass2 ">
                            <img className='profileIcon' src={localStorage.getItem("profile") ? (MEDIA_URL + localStorage.getItem("profile")) : '/assets/icons/default_avatar.jpg'} />
                        </Link>

                    </>}

                    <Link to="/basket" className="tl_nav_link cc_smallermargin cc_navmediulink ">
                        {basketItems.sessions.length + basketItems.trainings.length > 0 ? <span className="badge badge-pill badge-basket">{basketItems.sessions.length + basketItems.trainings.length}</span> : ''}<CIcon name="cilBasket"></CIcon>
                    </Link>
                    <Link to={isMedicalPage ? "/GMTC-medical-center/my-profile/favorite" : "/my-profile/favorite"} className="tl_nav_link cc_smallermargin cc_navmediulink favIconClass2 ">
                        {localStorage.getItem("auth") && favorites && favorites.length > 0 ? <span className="badge badge-pill badge-basket">{favorites.length}</span> : ''}     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" role="presentation" focusable="false"
                            style={{ display: "block", fill: "#f10621", stroke: "#e2001a", strokeWidth: "2", overflow: "visible" }}>
                            <path d="M16 28c7-4.73 14-10 14-17a6.98 6.98 0 0 0-7-7c-1.8 0-3.58.68-4.95 2.05L16 8.1l-2.05-2.05a6.98 6.98 0 0 0-9.9 0A6.98 6.98 0 0 0 2 11c0 7 7 12.27 14 17z"></path></svg>
                    </Link>


                </div>
                <div className="d_navrightside">
                    <div className="searchWrapper">
                        <div className="searchIcon"><img src="/assets/icons/search-icon.png" /></div>
                        <input className="input" placeholder='Search' />
                    </div>
                </div>
                <div className="nav_menu_contentMob">
                    <div className="w_navleftsidelinks w_navleftsidelinks1">

                        {!isMedicalPage ? <div className="w_navleftsidelinks">
                            <NavLink to="/trainings/3/master_class" activeClassName="w--current" className="tl_nav_link importantLink" onClick={closeMobileMenu}>{t("master_class")}</NavLink>
                            <NavLink to="/trainings/5/informatique" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("Informatique")}</NavLink>
                            <NavLink to="/trainings/6/art_et_metier" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("Art_and_craft")}</NavLink>
                            <NavLink to="/trainings/7/alimentaire" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>Alimentaire</NavLink>
                            <NavLink to="/trainings/8/medias" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("Media")}</NavLink>
                            <NavLink to="/trainings/9/kids" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>Kids</NavLink>
                            <NavLink to="/trainings/10/metier_d_artisant" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("Craftsmanship")}</NavLink>
                            {/* <NavLink to="/contact" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>IOT Security</NavLink>
                                        <NavLink to="/contact" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>Soft Skills</NavLink>
                                    */}
                        </div>
                            :
                            <div className="w_navleftsidelinks">
                                <NavLink to="/GMTC-medical-center/trainings/21/medical_master_class" activeClassName="w--current" className="tl_nav_link importantLink" onClick={closeMobileMenu}>{t("master_class")}</NavLink>
                                <NavLink to="/GMTC-medical-center/trainings/11/dental" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("Dental")}</NavLink>
                                <NavLink to="/GMTC-medical-center/trainings/12/ophthalmology" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("Ophthalmology")}</NavLink>
                                <NavLink to="/GMTC-medical-center/trainings/13/general-surgery" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("General_surgery")}</NavLink>
                                <NavLink to="/GMTC-medical-center/trainings/14/shoulder-surgery" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("Shoulder_surgery")}</NavLink>
                            </div>
                        }

                    </div>

                    <div className="w_navleftsidelinks staticLinksMob">

                        {isMedicalPage ?
                            <>
                                <ul>
                                    <li>
                                        <NavLink to="/GMTC-medical-center/contact" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("contact")}</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/GMTC-medical-center/event" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("Event")}</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/GMTC-medical-center/blog" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("Blog")}</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/GMTC-medical-center/about" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("about")}</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/GMTC-medical-center/staff" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("staff")}</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/GMTC-medical-center/philosophy" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("philosophy_link")}</NavLink>
                                    </li>
                                </ul>

                            </>
                            :
                            <>
                                <ul>
                                    <li>
                                        <NavLink to="/contact" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("contact")}</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/event" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("Event")}</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/blog" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("Blog")}</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/about" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("about")}</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/staff" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("staff")}</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/philosophy" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("philosophy_link")}</NavLink>
                                    </li>
                                </ul>
                            </>
                        }

                    </div>

                </div>
            </nav>
            <div className='desktopHeader' >
                <nav className="n_mainnavbar">
                    <div className="w_mobilebgcover"></div>
                    <div className="w_nav">
                        <div data-collapse="medium" data-animation="default" data-duration="500" data-doc-height="1"
                            data-w-id="392b72ab-2256-dd42-b8fd-e4ecdc60f83b" className="n_mainmobilenavbar w-nav">
                            <div className="c_navbar_mobile w-container">
                                {isMedicalPage ?
                                    <Link to="/GMTC-medical-center" aria-current="page" onClick={closeMobileMenu}
                                        className="logo w-nav-brand w--current"><img
                                            src="/assets/images/logo-gmtc.png"
                                            alt="GDTC Logo " className="img_navlogo" />
                                    </Link>
                                    : <Link to="/" aria-current="page" onClick={closeMobileMenu}
                                        className="logo w-nav-brand w--current"><img
                                            src="/assets/images/logo_gdtc.png"
                                            alt="GDTC Logo " className="img_navlogo" />
                                        {/* <CIcon name="cilHome" size={'xl'} className="primary" /> */}
                                    </Link>
                                }

                                <nav role="navigation" className="nav-menu w-nav-menu" id="nav"  >
                                    <div className="d_navleftside">
                                    </div>
                                    <div className="nav_menu_content">
                                        <div className="w_navleftsidelinks">
                                            <div className="w-embed">

                                            </div>
                                            {isMedicalPage ?
                                                <>
                                                    <NavLink to="/GMTC-medical-center/trainings/21/medical_master_class" activeClassName="w--current" className="tl_nav_link importantLink" onClick={closeMobileMenu}>{t("master_class")}</NavLink>
                                                    &nbsp; &nbsp; &nbsp;
                                                    <NavLink to="/GMTC-medical-center/philosophy" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("philosophy_link")}</NavLink>
                                                    <NavLink to="/GMTC-medical-center/event" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("Event")}</NavLink>
                                                    <NavLink to="/GMTC-medical-center/blog" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("Blog")}</NavLink>
                                                    <NavLink to="/GMTC-medical-center/about" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("about")}</NavLink>
                                                    <NavLink to="/GMTC-medical-center/staff" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("staff")}</NavLink>
                                                    <NavLink to="/GMTC-medical-center/contact" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("contact")}</NavLink>
                                                    <NavLink to="/GMTC-medical-center/trainer-apply" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("join_our_team")}</NavLink>

                                                </>
                                                :
                                                <>
                                                    <NavLink to="/trainings/3/master_class" activeClassName="w--current" className="tl_nav_link importantLink" onClick={closeMobileMenu}>{t("master_class")}</NavLink>
                                                    &nbsp; &nbsp; &nbsp;
                                                    <NavLink to="/philosophy" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("philosophy_link")}</NavLink>
                                                    <NavLink to="/event" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("Event")}</NavLink>
                                                    <NavLink to="/blog" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("Blog")}</NavLink>
                                                    <NavLink to="/about" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("about")}</NavLink>
                                                    <NavLink to="/staff" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("staff")}</NavLink>
                                                    <NavLink to="/contact" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("contact")}</NavLink>
                                                    <NavLink to="/trainer-apply" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("join_our_team")}</NavLink>
                                                </>
                                            }

                                            {/* <span to="/trainings" className="tl_nav_link dropdown" onClick={closeMobileMenu}>{t("courses_link")}
                                            <div className="dropdown-content">
                                                {categories.map((category, index) => {
                                                    return (
                                                        <Link key={index} to={`/trainings/${category.id}/${category.slug}`}
                                                        >{category.label} <br /></Link>
                                                    )
                                                })}


                                            </div>
                                        </span> */}

                                            {/* <NavLink to="/vision" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t('our_vision_link')}</NavLink>
                                        <NavLink to="/project" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t('our_project_link')}</NavLink>
                                        <NavLink to="/philosophy" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("philosophy_link")}</NavLink>
                                       
                                        <span to="/trainings" className="tl_nav_link dropdown" onClick={closeMobileMenu}>{t("courses_link")}
                                            <div className="dropdown-content">
                                                {categories.map((category, index) => {
                                                    return (
                                                        <Link key={index} to={`/trainings/${category.id}/${category.slug}`}
                                                        >{category.label} <br /></Link>
                                                    )
                                                })}


                                            </div>
                                        </span>
                                        <span href="#" className="tl_nav_link dropdown">{t("study_club_link")}
                                            <div className="dropdown-content">
                                                <a href="http://www.minec.ac/" target="_blank"
                                                >MINEC Study Club Tunisia</a><br />
                                                <a href="https://www.slowdentistry.com/" target="_blank"
                                                >Slow Dentistry Tunisia Study club</a><br />
                                                <a href="https://digital-dentistry.org/" target="_blank"
                                                >Digital Dentistry Study Club </a><br />
                                                <a href="#">GDTC Study Club </a><br />
                                            </div>
                                        </span>
                                        <NavLink to="/event" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("events_link")}</NavLink>
                                        <NavLink to="/partners" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}> {t("partners_link")} </NavLink>
                                        <NavLink to="/contact" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}> {t("contact_link")} </NavLink>
                                   */}

                                        </div>
                                    </div>

                                    <div className="d_mobilenavdivider"></div>
                                    <div className="d_navrightside">

                                        {/**  <a href="{{ path('logout') }}" className="tl_nav_link cc_smallermargin cc_navmediulink">Déconnexion</a>
*/}
                                        {localStorage.getItem("auth") && <>
                                            <div className="myItemsLinkWrapper">
                                                <Link
                                                    to={isMedicalPage ? "/GMTC-medical-center/my-profile/my-items" : "/my-profile/my-items"}
                                                    className="myItemsLink tl_nav_link cc_smallermargin cc_navmediulink favIconClass2"
                                                >
                                                    <img className="profileIcon" src="/assets/icons/my-course.png" alt="My Course" />
                                                </Link>
                                                <div className="tooltip"> {t("my_courses")}</div>
                                            </div>
                                            <Link to={isMedicalPage ? "/GMTC-medical-center/my-profile" : "/my-profile"} className="profileIconLink tl_nav_link cc_smallermargin cc_navmediulink favIconClass2 ">
                                                <img className='profileIcon' src={localStorage.getItem("profile") ? (MEDIA_URL + localStorage.getItem("profile")) : '/assets/icons/default_avatar.jpg'} />
                                            </Link>

                                        </>}
                                        {!localStorage.getItem("auth") && isMedicalPage ?
                                            <span style={{ width: 'max-content' }} //to="/GMTC-medical-center#login" 
                                                className="tl_nav_link cc_smallermargin cc_navmediulink" onClick={handleLoginClick}>{t("login")} <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-in"><path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4" fill="white"></path><polyline points="10 17 15 12 10 7"></polyline><line x1="15" y1="12" x2="3" y2="12"></line></svg></span>
                                            : !localStorage.getItem("auth") && !isMedicalPage ? <Link to="/login" style={{ width: 'max-content' }} className="tl_nav_link cc_smallermargin cc_navmediulink" onClick={closeMobileMenu}>
                                                {t("login")} <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-in"><path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4" fill="white"></path><polyline points="10 17 15 12 10 7"></polyline><line x1="15" y1="12" x2="3" y2="12"></line></svg></Link>
                                                : ""}
                                        <Link to="/basket" className="tl_nav_link cc_smallermargin cc_navmediulink ">
                                            {basketItems.sessions.length + basketItems.trainings.length > 0 ? <span className="badge badge-pill badge-basket">{basketItems.sessions.length + basketItems.trainings.length}</span> : ''}<CIcon name="cilBasket"></CIcon>
                                        </Link>
                                        <Link to={isMedicalPage ? "/GMTC-medical-center/my-profile/favorite" : "/my-profile/favorite"} className="tl_nav_link cc_smallermargin cc_navmediulink favIconClass2 ">
                                            {localStorage.getItem("auth") && favorites && favorites.length > 0 ? <span className="badge badge-pill badge-basket">{favorites.length}</span> : ''}     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" role="presentation" focusable="false"
                                                style={{ display: "block", fill: "#f10621", stroke: "#e2001a", strokeWidth: "2", overflow: "visible" }}>
                                                <path d="M16 28c7-4.73 14-10 14-17a6.98 6.98 0 0 0-7-7c-1.8 0-3.58.68-4.95 2.05L16 8.1l-2.05-2.05a6.98 6.98 0 0 0-9.9 0A6.98 6.98 0 0 0 2 11c0 7 7 12.27 14 17z"></path></svg>
                                        </Link>
                                        {localStorage.getItem("auth") && <>
                                            <div className="hover-link-container">
                                                <div to="/" onClick={handleLogout} className="hover-link tl_nav_link cc_smallermargin cc_navmediulink" style={{ marginRight: "10px", cursor: "pointer" }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                                                    <span className="hover-text">{t("logout")}</span>
                                                </div>
                                            </div>

                                        </>}

                                        <div className="searchWrapper">
                                            <div className="searchIcon"><img src="/assets/icons/search-icon.png" /></div>
                                            <input className="input" placeholder='Search' />
                                        </div>
                                        <div className='changeLang'>
                                            <span onClick={(e) => changeLanguage('fr')}
                                                className={`${lang == 'en' ? 'showLangFlag' : 'hideLangFlag'}  tl_nav_link cc_smallermargin cc_navmediulink `}>
                                                Français  <img src={"/assets/images/fr.png"} className="flag" /></span>
                                            <span onClick={(e) => changeLanguage('en')}
                                                className={`${lang == 'fr' ? 'showLangFlag' : 'hideLangFlag'} tl_nav_link cc_smallermargin cc_navmediulink`}>
                                                English <img src={"/assets/images/en.png"} className="flag" /></span>

                                        </div>


                                        <div data-w-id="515a474a-883e-6da8-0b48-9895f0629a36" className="nav_mobile"></div>
                                    </div>

                                </nav>
                                <div className="btn_mobile_nav w-nav-button" onClick={handleClick}>
                                    <div className="burger-button-wrapper">
                                        <div className="burger-line-top"></div>
                                        <div className="burger-line-middle"></div>
                                        <div className="burger-line-bottom"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>

                <nav role="navigation" className="nav-menu w-nav-menu secondNav"  >
                    <div className="nav_menu_content">
                        {!isMedicalPage ? <div className="w_navleftsidelinks">
                            <NavLink to="/trainings/5/informatique" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("Informatique")}</NavLink>
                            <NavLink to="/trainings/6/art_et_metier" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("Art_and_craft")}</NavLink>
                            <NavLink to="/trainings/7/alimentaire" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>Alimentaire</NavLink>
                            <NavLink to="/trainings/8/medias" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("Media")}</NavLink>
                            <NavLink to="/trainings/9/kids" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>Kids</NavLink>
                            <NavLink to="/trainings/10/metier_d_artisant" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("Craftsmanship")}</NavLink>
                            {/* <NavLink to="/contact" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>IOT Security</NavLink>
                                        <NavLink to="/contact" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>Soft Skills</NavLink>
                                    */}
                        </div>
                            :
                            <div className="w_navleftsidelinks">
                                <NavLink to="/GMTC-medical-center/trainings/11/dental" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("Dental")}</NavLink>
                                <NavLink to="/GMTC-medical-center/trainings/12/ophthalmology" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("Ophthalmology")}</NavLink>
                                <NavLink to="/GMTC-medical-center/trainings/13/general-surgery" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("General_surgery")}</NavLink>
                                <NavLink to="/GMTC-medical-center/trainings/14/shoulder-surgery" activeClassName="w--current" className="tl_nav_link" onClick={closeMobileMenu}>{t("Shoulder_surgery")}</NavLink>
                            </div>
                        }
                    </div>



                </nav>
            </div>
            <div className='mobHeader'>
                <nav className="n_mainnavbar">
                    <div className="w_nav">
                        <div data-collapse="medium" data-animation="default" data-duration="500" data-doc-height="1"
                            data-w-id="392b72ab-2256-dd42-b8fd-e4ecdc60f83b" className="n_mainmobilenavbar w-nav">
                            <div className="c_navbar_mobile w-container">
                                {isMedicalPage ?
                                    <Link to="/GMTC-medical-center" aria-current="page" onClick={closeMobileMenu}
                                        className="logo w-nav-brand w--current"><img
                                            src="/assets/images/logo-gmtc.png"
                                            alt="GDTC Logo " className="img_navlogo" />
                                    </Link>
                                    : <Link to="/" aria-current="page" onClick={closeMobileMenu}
                                        className="logo w-nav-brand w--current"><img
                                            src="/assets/images/logo_gdtc.png"
                                            alt="GDTC Logo " className="img_navlogo" />
                                        {/* <CIcon name="cilHome" size={'xl'} className="primary" /> */}
                                    </Link>
                                }

                                <div className="d_navrightside d_navrightsideMob">

                                    {/**  <a href="{{ path('logout') }}" className="tl_nav_link cc_smallermargin cc_navmediulink">Déconnexion</a>
*/}
                                    {localStorage.getItem("auth") && <>
                                        <Link to={isMedicalPage ? "/GMTC-medical-center/my-profile" : "/my-profile"} className="profileIconLink tl_nav_link cc_smallermargin cc_navmediulink favIconClass2 ">
                                            <img className='profileIcon' src={localStorage.getItem("profile") ? (MEDIA_URL + localStorage.getItem("profile")) : '/assets/icons/default_avatar.jpg'} />
                                        </Link>

                                    </>}
                                    {!localStorage.getItem("auth") && isMedicalPage ?
                                        <span style={{ width: 'max-content' }} //to="/GMTC-medical-center#login" 
                                            className="tl_nav_link cc_smallermargin cc_navmediulink" onClick={handleLoginClick}>{t("login")} <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-in"><path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4" fill="white"></path><polyline points="10 17 15 12 10 7"></polyline><line x1="15" y1="12" x2="3" y2="12"></line></svg></span>
                                        : !localStorage.getItem("auth") && !isMedicalPage ? <Link to="/login" style={{ width: 'max-content' }} className="tl_nav_link cc_smallermargin cc_navmediulink" onClick={closeMobileMenu}>
                                            {t("login")} <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-in"><path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4" fill="white"></path><polyline points="10 17 15 12 10 7"></polyline><line x1="15" y1="12" x2="3" y2="12"></line></svg></Link>
                                            : ""}
                                    {localStorage.getItem("auth") && <>
                                        <div className="hover-link-container">
                                            <div to="/" onClick={handleLogout} className="hover-link tl_nav_link cc_smallermargin cc_navmediulink" style={{ marginRight: "10px", cursor: "pointer" }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                                                <span className="hover-text">{t("logout")}</span>
                                            </div>
                                        </div>

                                    </>}
                                    <div className='changeLangMob'>
                                        <span onClick={(e) => changeLanguage('fr')}
                                            className={`${lang == 'en' ? 'showLangFlag' : 'hideLangFlag'}  tl_nav_link cc_smallermargin cc_navmediulink `}>
                                            <img src={"/assets/images/fr.png"} className="flag" /></span>
                                        <span onClick={(e) => changeLanguage('en')}
                                            className={`${lang == 'fr' ? 'showLangFlag' : 'hideLangFlag'} tl_nav_link cc_smallermargin cc_navmediulink`}>
                                            <img src={"/assets/images/en.png"} className="flag" /></span>

                                    </div>

                                    <div data-w-id="515a474a-883e-6da8-0b48-9895f0629a36" className="nav_mobile"></div>
                                </div>

                                <div className="btn_mobile_nav w-nav-button" onClick={handleClick}>
                                    <div className="burger-button-wrapper">
                                        <div className="burger-line-top"></div>
                                        <div className="burger-line-middle"></div>
                                        <div className="burger-line-bottom"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>

        </>
    )
}

const mapStateToProps = (state) => {
    console.log(state.basket)
    // setErrors(state.auth.error);
    return {
        basketItems: state.basket.basketItems,

    }
}

export default connect(mapStateToProps, actions)(Navbar)
